import { CouponsProvider } from 'context/CouponsContext';

import Plans from './tables/Plans';
import Section from 'components/Section';
import Generator from './tables/Generator';
import ActiveCoupons from './tables/ActiveCoupons';

import styles from './styles.module.scss';

const PlanCuponGerenerator = () => (
  <div className={styles.page}>
    <Section 
      title='Plans'
      table={<Plans />}
    />

    <CouponsProvider>
      <Section 
        title='Coupon Generator'
        table={<Generator />}
      />
      <Section 
        title='Coupons'
        table={<ActiveCoupons />}
      />
    </CouponsProvider>
  </div>
);

export default PlanCuponGerenerator;