import { couponMaxLength, couponCharacters } from 'constants';

import Input from 'components/Input'

const getCouponCode = (rowId, couponsList) => couponsList?.find(item => item._id === rowId)?.couponCode || '';

export const onClickCopy = (rowId, couponsList) => {
  const couponeCode = getCouponCode(rowId, couponsList);

  navigator.clipboard.writeText(couponeCode)
};

export const onClickShare = (rowId, couponsList) => {
  const couponeCode = getCouponCode(rowId, couponsList);

  window.open(`mailto:?body=Coupon code: ${couponeCode}`);
};

export const makeCoupon = () => {
  let result = '';
  const charactersLength = couponCharacters.length;
  let counter = 0;

  while (counter < couponMaxLength) {
    result += couponCharacters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
};

export const getCardRows = ({ rows, className }) => {
  const cardRows = [];

  Object.keys(rows).forEach((key) => {
    cardRows.push(
      <div className={className} key={`${key}-${rows[key]}`}>
        {rows[key]}
      </div>
    )
  });

  return cardRows;
};

export const getPlansFirstColumn = (rows, values = {}, classNameTitle, classNameCell, deleteRow, classNameWrapper, onChange) => {
  if (!rows || Array.isArray(values)) return null;

  const { price, ...rest } = rows;
  const sortedRows = { ...rest, price };

  const cells = Object.entries(sortedRows)?.map(([key, value]) => {
    if (key === '_id') {
      return (
        <div className={classNameTitle} key={key + value}>
          Plan
        </div>
      )
    }
    
    if (key === 'price') {
      const priceRows = values?.data.length > 0 && Object.entries(values?.data?.[0]?.rows?.price)?.map(([keyPrice, valuePrice]) => (
        <div className={classNameCell} key={keyPrice + valuePrice}>
          {`${value} - ${keyPrice}`}
        </div>
      ));

      return priceRows;
    }

    return (
      <div className={classNameWrapper} key={key + value}>
        {deleteRow(key)}
        <div className={classNameCell}>
          <Input
            onChange={onChange}
            name={`plansUnits.${key}`}
            value={values?.plansUnits?.[key] || ''}
          />
        </div>
      </div>
    )
  });

  return cells;
};

export const getPlansColumns = (rows, index, className, values = {}, onChange) => {
  if (!rows) return null;

  const { price, ...rest } = rows;
  const sortedRows = { ...rest, price };

  const cells = Object.entries(sortedRows)?.map(([key, value]) => {
    if (key === 'price') {
      const priceRows = Object.entries(value)?.map(([keyPrice, valuePrice]) => (
        <div className={className} key={key + keyPrice}>
          <Input
            onChange={onChange}
            name={`data.[${index}].rows.${key}.${keyPrice}`}
            value={values?.data?.[index]?.rows[key][keyPrice] || ''}
          />
        </div>
      ));

      return priceRows;
    }

    return (
      <div className={className} key={key + value}>
        <Input
          onChange={onChange}
          name={`data.[${index}].rows.${key}`}
          value={values?.data?.[index]?.rows[key] || ''}
        />
      </div>
    )
  });

  return cells;
};

export const getErrorMessage = (errors) => {
  const keys = Object.keys(errors);
  const firstErrorValue = errors[keys[0]];

  if (keys.length < 1) return null;

  // if object -> go deep while get message
  if (typeof firstErrorValue === 'object' && firstErrorValue !== null) {
    return getErrorMessage(firstErrorValue);
  } else {
    return firstErrorValue;
  }
};